import React from 'react';
import ReactDOM from 'react-dom/client';
import {Configuration, InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate
} from "@azure/msal-react";

import './index.module.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StoreProvider} from './storeApi/store';

// TODO how to do this with function components?
// ReactModal.setAppElement(App);

// MSAL configuration
const configuration: Configuration = {
  auth: {
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '', // will fail if something is wrong with ENV
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
  }
};
const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function ErrorComponent({error}: MsalAuthenticationResult) {
  return <p>An Error Occurred: {error?.errorMessage}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

root.render(
  <React.StrictMode>
    {/*<MsalProvider instance={pca}>*/}
    {/*  <MsalAuthenticationTemplate*/}
    {/*    interactionType={InteractionType.Redirect}*/}
    {/*    // authenticationRequest={authRequest}*/}
    {/*    errorComponent={ErrorComponent}*/}
    {/*    loadingComponent={LoadingComponent}*/}
    {/*  >*/}
        <StoreProvider>
          <App/>
        </StoreProvider>
    {/*  </MsalAuthenticationTemplate>*/}
    {/*  <UnauthenticatedTemplate>*/}
    {/*    <p>You are not authenticated!</p>*/}
    {/*  </UnauthenticatedTemplate>*/}
    {/*</MsalProvider>*/}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
