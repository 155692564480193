import React from 'react';
import styles from './plate.module.css';
import {useActive, usePlate} from "../storeApi/apis";

function Plate() {
  const {plateSelected, setPlateSelected, active, plates, setActivePlateName, initPlate} = useActive();
  const {plateName} = active;
  const {plate, getWellColor} = usePlate(plateName);
  const setSelected = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    if (plateSelected === event.currentTarget.id) {
      setPlateSelected('');
    } else {
      setPlateSelected(event.currentTarget.id);
    }
  }
  const renderPlate = (plate: any) => {
    const renderRows = () => plate.map((row: any, rowIdx: number) => (
      <tr>
        {row.map((well: any, columnIdx: number) => <td
          id={`${plateName}-` + rowIdx + '-' + columnIdx}
          onClick={(e) => setSelected(e)}
        ><span
          className={styles[getWellColor(columnIdx, rowIdx)]}
        >{well.id}<span className={styles.usedTimes}>{well.usedTimes > 0 ? ` [${well.usedTimes}]` : ''}</span></span>
        </td>)}
      </tr>
    ));
    if (active.plateName === '') { // if no plate yet exists
      return (
        <div>
          <p>No plate selected.</p>
        </div>
      );
    }
    return (
      <table id={`plate-${plateName}`}>
        <tbody>
        {renderRows()}
        </tbody>
      </table>
    )
  }
  const renderPlateSelect = Object.entries(plates).map(([key]) => (
    <option value={key}>{key}</option>
  ));
  const setActive = (event: any) => {
    let option;
    for (let i = 0; i < event.target.length; i++) {
      if (event.target[i].selected === true) {
        option = event.target[i].value;
      }
    }
    setActivePlateName(option);
  }
  const createNewPlateHandler = (event: any) => {
    let newPlateName;
    const children = event.target.parentElement.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].id === 'input-new-plate-name') {
        newPlateName = children[i].value;
      }
    }
    createNewPlate(newPlateName);
  }
  const createNewPlate = (newPlateName: string) => {
    if (plates[newPlateName] !== undefined) {
      // TODO error msg
      return;
    }
    initPlate(newPlateName);
    setActivePlateName(newPlateName);
  }
  const createNewPlateEnterHandler = (event: any) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      createNewPlate(event.target.value)
    }
  }
  return (
    <div key={`plate-${plateName}`}>
      <select onChange={setActive} value={plateName}>
        {renderPlateSelect}
      </select>
      <input id='input-new-plate-name' placeholder='Create new plate' onKeyUp={createNewPlateEnterHandler}/>
      <button onClick={createNewPlateHandler}>Create</button>
      {renderPlate(plate)}
    </div>
  );
}

export default Plate;
