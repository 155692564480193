import React, {useState} from 'react';
import styles from './puck.module.css';
import {useActive, usePlate, usePuck} from "../storeApi/apis";
import {useModal} from "./commitModalHook";
import ReactModal from "react-modal";

function Puck() {
  const {
    plateSelected,
    setPlateSelected,
    puckSelected,
    setPuckSelected,
    active,
    addAction,
    setActivePuckId,
    pucks,
    initPuck,
  } = useActive();
  const {puckId} = active;
  const [commitComment, setCommitComment] = useState<string | undefined>(undefined);
  const {isShowing, toggle} = useModal();
  const {puck, getPositionInPuck, getWellColor, setWellUsed, setWellContent} = usePuck(puckId);
  const {setWellUsed: setPlateWellUsed, getPositionInPlate, plate} = usePlate(active.plateName);
  const commitPuckInsertion = () => {
    if (plateSelected && isShowing) {
      const fishedDate = new Date();
      setPlateSelected(''); // unset
      setPuckSelected(''); // unset
      setPlateWellUsed(plateSelected);
      setWellUsed(puckSelected, fishedDate);
      setWellContent(plateSelected, puckSelected, commitComment);
      addAction(plateSelected, puckSelected, commitComment, fishedDate);
      // close modal
      toggle();
      setPuckSelected('');
    }
  }
  const setSelected = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    const {columnIdx, rowIdx} = getPositionInPuck(event.currentTarget.id);
    if (puck[parseInt(rowIdx)][parseInt(columnIdx)].used === true) {
      return; // don't select if already used
    }
    if (plateSelected && !isShowing) {
      // open modal
      setPuckSelected(event.currentTarget.id);
      toggle();
    }
  }
  const renderPuck = (puck: any) => {
    const renderRows = () => puck.map((row: any, rowIdx: number) => (
      <tr>
        {row.map((well: any, columnIdx: number) => <td
          id={`${puckId}-` + rowIdx + '-' + columnIdx}
          onClick={(e) => setSelected(e)}
        ><span
          className={styles[getWellColor(columnIdx, rowIdx)]}
        >{`${well.i}`}</span></td>)}
      </tr>
    ));
    if (active.plateName === '') { // wait for plate to load
      return (
        <div>
          <p>No plate selected.</p>
        </div>
      );
    }
    if (active.puckId === '') { // if no puck yet exists
      return (
        <div>
          <p>No puck selected.</p>
        </div>
      );
    }
    return (
      <table id={`puck-${puckId}`}>
        <tbody>
        {renderRows()}
        </tbody>
      </table>
    )
  }
  const renderPuckSelect = Object.entries(pucks).map(([key]) => (
    <option value={key}>{key}</option>
  ));
  const setActive = (event: any) => {
    let option;
    for (let i = 0; i < event.target.length; i++) {
      if (event.target[i].selected === true) {
        option = event.target[i].value;
      }
    }
    setActivePuckId(option);
  }
  const createNewPuckHandler = (event: any) => {
    let newPuckId;
    const children = event.target.parentElement.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].id === 'input-new-puck-id') {
        newPuckId = children[i].value;
      }
    }
    createNewPuck(newPuckId);
  }
  const createNewPuck = (newPuckId: string) => {
    if (pucks[newPuckId] !== undefined) {
      // TODO error msg
      return;
    }
    initPuck(newPuckId);
    setActivePuckId(newPuckId);
  }
  const createNewPuckEnterHandler = (event: any) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      createNewPuck(event.target.value)
    }
  }
  const getCurrentPlateWellId = (): string => {
    if (plateSelected !== '') {
      const coords = getPositionInPlate(plateSelected);
      return plate[coords.rowIdx][coords.columnIdx].id;
    }
    return '';
  };
  const getCurrentPuckWellId = (): string => {
    if (puckSelected !== '') {
      const coords = getPositionInPuck(puckSelected);
      return puck[coords.rowIdx][coords.columnIdx].i;
    }
    return '';
  };
  return (
    <div key={`puck-${puckId}`}>
      <select onChange={setActive} value={puckId}>
        {renderPuckSelect}
      </select>
      <input id='input-new-puck-id' placeholder='Create new puck' onKeyUp={createNewPuckEnterHandler}/>
      <button onClick={createNewPuckHandler}>Create</button>
      {renderPuck(puck)}
      <ReactModal
        isOpen={isShowing}
        contentLabel="Commit putting crystal into puck"
      >
        <p>{active.plateName} -&gt; {active.puckId}</p>
        <p>Transferring from plate position <strong>{getCurrentPlateWellId()}</strong> to
          puck position <strong>{getCurrentPuckWellId()}</strong>.</p>
        <p>Add an optional comment and press <strong>Commit</strong> to finalize.</p>
        <p>Comment: <input
          id='input-commit-comment'
          placeholder='Add optional comment'
          value={commitComment}
          onChange={(evt) => setCommitComment(evt.target.value)}
        /></p>
        <button className={styles.huge} onClick={toggle}>&#10060;</button>
        <button className={styles.huge} onClick={commitPuckInsertion}>&#9989;</button>
      </ReactModal>
    </div>
  );
}

export default Puck;
