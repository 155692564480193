import React, {createContext, useContext, useReducer} from 'react';
import {v4 as uuidv4} from 'uuid';

const contextInit: ContextType = {
  state: undefined,
  dispatch: (action: ActionType) => undefined,
}; // TODO any

const genEmptyPlate = (): PlateContent[][] => {
  const rows = ['A', 'B', 'C', 'D'], columns = ['1', '2', '3', '4', '5', '6'];
  return rows.map((row) => columns.map((column) => ({ // add pins
    id: `${row}${column}`,
    pin: undefined, // pin data
    used: false, // n-1 with puck
    usedTimes: 0 // TODO not the best way to do that -> rather count real links (maybe IDs are available?)
  })));
}

const genEmptyPuck = (): PuckContent[][] => {
  const elements = 16, columns = 4;
  const res: PuckContent[][] = [];
  for (let i = 1; i <= elements; i++) {
    if (res[(i - 1) % columns] === undefined) {
      res[(i - 1) % columns] = [];
    }
    res[Math.floor((i - 1) / columns)][(i - 1) % columns] = {
      i,
      pin: undefined, // pin data
      used: false,
      usedAt: undefined, // date-time
      comment: undefined
    };
  }
  return res;
}

const StoreContext = createContext(contextInit);
const emptyState = {
  active: {
    plateName: '',
    puckId: ''
  },
  plateCellIdSelected: '',
  puckCellIdSelected: '',
  plates: {},
  pucks: {},
  actions: []
};

const resetState = () => {
  const emptyLocalStateString = JSON.stringify(emptyState);
  localStorage.setItem('state', emptyLocalStateString);
  return JSON.parse(emptyLocalStateString);
}
const getInitialState = () => {
  const localStateString = localStorage.getItem('state');
  if (localStateString !== null) {
    return JSON.parse(localStateString);
  }
  return resetState();
}
const withLocalStorage = (state: any) => {
  localStorage.setItem('state', JSON.stringify(state));
  return state;
}

// from https://react.christmas/2019/7
const reducer = (state: any, action: any) => { // TODO any
  const evaluate = () => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "app.reset":
        return action.msg ? resetState() : state;
      case "plate.init":
        newState.plates[action.msg] = {data: genEmptyPlate()};
        return newState;
      case "puck.init":
        newState.pucks[action.msg] = {data: genEmptyPuck()};
        return newState;
      case "plate.clear":
        newState.plates[action.msg] = {data: genEmptyPlate()};
        return newState;
      case "puck.clear":
        newState.pucks[action.msg] = {data: genEmptyPuck()};
        return newState;
      case "plate.selected.set":
        return {...state, plateCellIdSelected: action.msg};
      case "puck.selected.set":
        return {...state, puckCellIdSelected: action.msg};
      case 'active.plateName.set':
        return {...state, active: {...state.active, plateName: action.msg}};
      case 'active.puckId.set':
        return {...state, active: {...state.active, puckId: action.msg}};
      case 'plate.used.set':
        newState.plates[action.msg.plateName].data[action.msg.rowIdx][action.msg.columnIdx].used = true;
        newState.plates[action.msg.plateName].data[action.msg.rowIdx][action.msg.columnIdx].usedTimes += 1;
        return newState;
      case 'puck.used.set': // TODO check if already used
        newState.pucks[action.msg.puckId].data[action.msg.rowIdx][action.msg.columnIdx].used = true;
        newState.pucks[action.msg.puckId].data[action.msg.rowIdx][action.msg.columnIdx].usedAt = action.msg.fishedDate;
        return newState;
      case "puck.content.set": // TODO use plate.well.pin rather than id as it becomes populated
        const plateWellContent = `${state.active.plateName}/${state.plates[state.active.plateName].data[action.msg.plateRowIdx][action.msg.plateColumnIdx].id}`;
        newState.pucks[state.active.puckId].data[action.msg.puckRowIdx][action.msg.puckColumnIdx].pin = plateWellContent;
        newState.pucks[state.active.puckId].data[action.msg.puckRowIdx][action.msg.puckColumnIdx].comment = action.msg.commitComment;
        return newState;
      case "actions.add":
        const plateWellId = state.plates[state.active.plateName].data[action.msg.plateRowIdx][action.msg.plateColumnIdx].id;
        const puckWellI = state.pucks[state.active.puckId].data[action.msg.puckRowIdx][action.msg.puckColumnIdx].i;
        const date = action.msg.fishedDate;
        return {
          ...state, actions: [...state.actions, {
            id: uuidv4(),
            plateName: state.active.plateName,
            plateWellId,
            puckId: state.active.puckId,
            puckWellI,
            date,
            commitComment: action.msg.commitComment,
            deleted: false
          }]
        };
      case "actions.undo": // TODO actual undo, rather than just clearing pucks
        // plate
        const plateToClear = newState.plates[action.msg.plateName].data
          .reduce((acc: PlateContent | undefined, val: PlateContent[]) => {
            const res = val.find((column: PlateContent) => column.id === action.msg.plateWellId);
            return res ? res : acc;
          }, undefined);
        if (plateToClear.usedTimes === 1) {
          plateToClear.used = false;
        }
        plateToClear.usedTimes -= 1;
        // puck
        const puckToClear = newState.pucks[action.msg.puckId].data
          .reduce((acc: PuckContent | undefined, val: PuckContent[]) => {
            const res = val.find((column: PuckContent) => column.i === action.msg.puckWellI);
            return res ? res : acc;
          }, undefined);
        puckToClear.pin = undefined;
        puckToClear.comment = undefined;
        puckToClear.used = false;
        // actions
        const actionToDelete = newState.actions.find((a: HistoryType) => a.id === action.msg.actionId);
        actionToDelete.deleted = true;
        return newState;
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  return withLocalStorage(evaluate());
}

export const StoreProvider = ({children}: any) => { // TODO any
  const [state, dispatch] = useReducer(reducer, getInitialState());

  return (
    <StoreContext.Provider value={{state, dispatch}}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => useContext(StoreContext);
