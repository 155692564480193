import React from 'react';
import styles from './history.module.css';
import {useActive} from "../storeApi/apis";

function History() {
  const {actions, undoAction} = useActive();
  const renderActions = () => {
    return actions.map((a: HistoryType) => (
      <tr className={a.deleted ? styles.deleted : ''}>
        <td>{a.plateName}</td>
        <td>{a.plateWellId}</td>
        <td>{a.puckId}</td>
        <td>{a.puckWellI}</td>
        <td>{new Date(a.date).toLocaleString()}</td>
        <td>{a.commitComment}</td>
        {!a.deleted && <td className={styles.pointy} onClick={() => undoAction(a)}>&#128465;</td>}
      </tr>
    ))
  }
  return (
    <div>
      <table>
        <thead>
        <tr>
          <td>Plate Name</td>
          <td>Plate Well</td>
          <td>Puck ID</td>
          <td>Puck Well</td>
          <td>Date</td>
          <td>Comment</td>
        </tr>
        </thead>
        <tbody>
        {renderActions()}
        </tbody>
      </table>
    </div>
  );
}

export default History;
