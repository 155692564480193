import React, {useEffect, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import GridLayout from "react-grid-layout";

import {useMsal} from "@azure/msal-react";
import {AccountInfo} from "@azure/msal-browser";

import Plate from "./plate/plate";
import Puck from "./puck/puck";
import History from "./history/history";
import Export from "./export/export";

import {useActive} from "./storeApi/apis";

import 'react-tabs/style/react-tabs.css';

import styles from './App.module.css';


const layout = [
  {i: 'welcome', x: 0, y: 0, w: 5, h: 1, static: true},
  {i: 'plate', x: 0, y: 1, w: 2, h: 5, static: true},
  {i: 'puck', x: 2, y: 1, w: 1, h: 5, static: true},
  {i: 'actions', x: 4, y: 0, w: 1, h: 5, static: true},
  {i: 'history', x: 0, y: 9, w: 5, h: 2, static: true}
];

// TODO prevent init of Plate and Puck with same name
function App() {
  const {
    reset,
    clearPuck,
    clearPlate,
    active
  } = useActive();
  //const [loggedIn, setLoggedIn] = useState<boolean>(false);
  //const {accounts, instance} = useMsal();
  // const checkApiLoggedIn = async () => {
  //   console.log(loggedIn);
  //   if (loggedIn) {
  //     return;
  //   }
  //   const token = await instance.acquireTokenSilent({
  //     scopes: [process.env.REACT_APP_AUTH_BENCHLING_SCOPE || ''], // will fail if sth. is wrong with ENV
  //     account: accounts[0]
  //   });
  //   const res = await fetch(
  //     process.env.REACT_APP_BACKEND_BASE_URL || '', // will fail if sth. is wrong with ENV
  //     {
  //       headers: {
  //         'Authorization': `Bearer ${token.accessToken}`
  //       }
  //     }
  //   );
  //   setLoggedIn(res.status === 200);
  // };
  // useEffect(() => {
  //   if (!loggedIn) {
  //     checkApiLoggedIn();
  //   }
  // });
  // const msalLogout = (account: AccountInfo) => {
  //   instance.logoutRedirect({
  //     account
  //   });
  // }
  return (
    <Tabs>
      <TabList>
        <Tab>Fish</Tab>
        <Tab>Export</Tab>
      </TabList>

      <TabPanel>
        <GridLayout
          className={styles.App}
          layout={layout}
          cols={4}
          rowHeight={30}
          width={1100}
        >
          <div key='welcome'>
            <p>
              THIS IS A TEST!
              {/*Welcome, {accounts[0].name ? accounts[0].name : accounts[0].username}!*/}
              {/*<button onClick={() => msalLogout(accounts[0])}>Logout</button>*/}
            </p>
          </div>
          <div key='plate'><Plate/></div>
          <div key='puck'><Puck/></div>
          <div key='actions'>
            <ul>
              <li>
                <button onClick={() => window.confirm('Do you really want to reset all plates and pucks? ' +
                  'This action is not reversible.') ? reset() : false}>Reset
                </button>
              </li>
              <li>
                <button onClick={() => window.confirm('Do you really want to clear the currently selected plate? ' +
                  'This action is not reversible.') ? clearPlate(active.plateName) : false}>Clear plate
                </button>
              </li>
              <li>
                <button onClick={() => window.confirm('Do you really want to clear the currently selected puck? ' +
                  'This action is not reversible.') ? clearPuck(active.puckId) : false}>Clear puck
                </button>
              </li>
            </ul>
          </div>
          <div key='history'><History/></div>
        </GridLayout>
      </TabPanel>
      <TabPanel>
        <Export/>
      </TabPanel>
    </Tabs>
  );
}

export default App;
