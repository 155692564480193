import {useStore} from "./store";
import Papa from "papaparse";
import FileSaver from "file-saver";

const getIdxFromCellId = (cellId: string) => {
  const [columnIdx, rowIdx] = cellId.split('-').reverse();
  return {columnIdx, rowIdx};
}

const useActive = () => {
  const {state, dispatch} = useStore();
  return {
    plates: state.plates,
    pucks: state.pucks,
    // ---
    active: state.active,
    initPlate: (plateName: string) => dispatch({type: 'plate.init', msg: plateName}),
    initPuck: (puckId: string) => dispatch({type: 'puck.init', msg: puckId}),
    clearPlate: (plateName: string) => dispatch({type: 'plate.clear', msg: plateName}),
    clearPuck: (puckId: string) => dispatch({type: 'puck.clear', msg: puckId}),
    setActivePlateName: (plateName: string) => dispatch({type: 'active.plateName.set', msg: plateName}),
    setActivePuckId: (puckId: string) => dispatch({type: 'active.puckId.set', msg: puckId}),
    plateSelected: state.plateCellIdSelected,
    puckSelected: state.puckCellIdSelected,
    setPuckSelected: (cellId: string) => dispatch({type: 'puck.selected.set', msg: cellId}),
    setPlateSelected: (cellId: string) => dispatch({type: 'plate.selected.set', msg: cellId}),
    reset: () => dispatch({type: 'app.reset', msg: true}),
    // --- actions
    actions: state.actions,
    addAction: (plateCellId: string, puckCellId: string, commitComment: string | undefined, fishedDate: Date) => {
      const {columnIdx: plateColumnIdx, rowIdx: plateRowIdx} = getIdxFromCellId(plateCellId);
      const {columnIdx: puckColumnIdx, rowIdx: puckRowIdx} = getIdxFromCellId(puckCellId);
      dispatch({type: 'actions.add', msg: {plateColumnIdx, plateRowIdx, puckColumnIdx, puckRowIdx, commitComment, fishedDate}});
    },
    downloadActionsAsCSV: () => {
      const actionsAsCSV = Papa.unparse(state.actions);
      const actionsBlob = new Blob([actionsAsCSV], {type: 'text/plain;charset=utf-8'});
      const date = new Date();
      const dateString = `${date.getUTCFullYear()}${date.getUTCMonth()}${date.getUTCDay()}${date.getUTCHours()}${date.getUTCMinutes()}${date.getUTCSeconds()}`;
      FileSaver.saveAs(actionsBlob, `${dateString}_cf_lab_app.csv`);
    },
    undoAction: (a: HistoryType) => {
      dispatch({
        type: 'actions.undo', msg: {
          actionId: a.id,
          plateName: a.plateName,
          puckId: a.puckId,
          plateWellId: a.plateWellId,
          puckWellI: a.puckWellI
        }
      });
    }
  };
}

const usePlate = (plateName: string) => {
  const {state, dispatch} = useStore();
  return {
    plate: plateName === '' ? undefined : state.plates[plateName].data,
    getPositionInPlate: (cellId: string) => {
      return getIdxFromCellId(cellId);
    },
    getWellColor: (columnIdx: number, rowIdx: number) => {
      let color = 'circle';
      if (state.plates[plateName].data[rowIdx][columnIdx].used) {
        color = 'used';
      }
      return `${plateName}-` + rowIdx + '-' + columnIdx === state.plateCellIdSelected ? 'selected' : color;
    },
    setWellUsed: (cellId: string) => {
      const {columnIdx, rowIdx} = getIdxFromCellId(cellId);
      dispatch({type: 'plate.used.set', msg: {plateName, columnIdx, rowIdx}})
    }
  };
};

const usePuck = (puckId: string) => {
  const {state, dispatch} = useStore();
  return {
    puck: puckId === '' ? undefined : state.pucks[puckId].data,
    getPositionInPuck: (cellId: string) => {
      return getIdxFromCellId(cellId);
    },
    getWellColor: (columnIdx: number, rowIdx: number) => {
      if (state.pucks[puckId].data[rowIdx][columnIdx].used) {
        return "used";
      }
      return `${puckId}-` + rowIdx + '-' + columnIdx === state.puckCellIdSelected ? 'selected' : 'circle';
    },
    setWellUsed: (cellId: string, fishedDate: Date) => {
      const {columnIdx, rowIdx} = getIdxFromCellId(cellId);
      dispatch({type: 'puck.used.set', msg: {puckId, columnIdx, rowIdx, fishedDate}})
    },
    setWellContent: (plateCellId: string, puckCellId: string, commitComment: string | undefined) => {
      const {columnIdx: plateColumnIdx, rowIdx: plateRowIdx} = getIdxFromCellId(plateCellId);
      const {columnIdx: puckColumnIdx, rowIdx: puckRowIdx} = getIdxFromCellId(puckCellId);
      dispatch({
        type: 'puck.content.set',
        msg: {plateColumnIdx, plateRowIdx, puckColumnIdx, puckRowIdx, commitComment}
      });
    }
  };
};


export {
  useActive,
  usePlate,
  usePuck,
};
