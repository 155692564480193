import React, {useState} from 'react';
import {useActive} from "../storeApi/apis";
import Papa from "papaparse";
import FileSaver from "file-saver";

function Export() {
  const {pucks} = useActive();
  const puckNames: string[] = [];
  for (const puck in pucks) {
    puckNames.push(puck);
  }
  const [checkedBoxes, setCheckedBoxes] = useState<boolean[]>(puckNames.map(() => false));
  const onCheckboxChange = (evt: any) => {
    const idx = puckNames.indexOf(evt.target.name);
    const newCheckedBoxes = JSON.parse(JSON.stringify(checkedBoxes));
    newCheckedBoxes[idx] = evt.target.checked;
    setCheckedBoxes(newCheckedBoxes);
  };
  const downloadPucksAsCSV = () => {
    const pucksToExport: any = [];
    for (const puck in pucks) {
      console.log(pucks[puck]);
      if (checkedBoxes[puckNames.indexOf(puck)]) {
        pucks[puck].data.forEach((row: PuckContent[]) => row.forEach((column: PuckContent) => pucksToExport.push({
          puckName: puck,
          well: column.i,
          pin: column.pin,
          used: column.used,
          usedAt: column.usedAt,
          comment: column.comment
        })));
      }
    }
    const pucksAsCSV = Papa.unparse(pucksToExport);
    const pucksBlob = new Blob([pucksAsCSV], {type: 'text/plain;charset=utf-8'});
    const date = new Date();
    const dateString = `${date.getUTCFullYear()}${date.getUTCMonth()}${date.getUTCDay()}${date.getUTCHours()}${date.getUTCMinutes()}${date.getUTCSeconds()}`;
    FileSaver.saveAs(pucksBlob, `${dateString}_export_cf_lab_app.csv`);
  };
  const renderPucks = () => {
    return puckNames.map((name: string) => (
      <label>
        <input
          type="checkbox"
          name={name}
          onChange={onCheckboxChange}
          checked={checkedBoxes[puckNames.indexOf(name)]}
        />
        {name}
      </label>
    ));
  };
  return (
    <div>
      <p>Select pucks to export</p>
      {renderPucks()}
      <p>
        <button onClick={downloadPucksAsCSV}>Download as CSV</button>
      </p>
    </div>
  );
}

export default Export;
